<template>
    <master-panel>
        <template v-slot:body>
            <v-row class="text-center">
                <v-col cols="12">
                    <div class="title">
                        Ya está todo listo para recibir a tus pacientes.
                    </div>
                    <div class="subtitle mt-1">
                        Si deseas, puedes invitar a tu equipo clínico y
                        administrativo, para mejorar la fluidez entre la
                        intención de consulta y la cirugía.
                    </div>
                </v-col>
                <v-col cols="12" class="mt-7">
                    <v-btn color="secondary" x-large depressed class="normal-btn"
                           @click="goTo({name: 'doctor.invitations.create', params: {role: 2}})">
                        Invitar a asistente clínico
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-btn color="ternary" x-large depressed class="normal-btn"
                           @click="goTo({name: 'doctor.invitations.create', params: {role: 3}})">
                        Invitar a secretaria
                    </v-btn>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-btn color="primary" x-large depressed class="normal-btn"
                   @click="goTo({name: 'doctor.invitations.finished'})">
                Siguiente
            </v-btn>
        </template>
    </master-panel>
</template>

<script>
import MasterPanel from "@/components/layouts/MasterPanel";

export default {
    name: "Invitations",
    components: {MasterPanel}
}
</script>

<style scoped>

.title {
    font-size: 15px !important;
    line-height: 18px;
    font-weight: bold;
}

.subtitle {
    font-size: 13px;
    line-height: 16px;
}

</style>